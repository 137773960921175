import React, { Fragment } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ILocation } from "../interface/location"
import MiljofyrtarnContent from "../components/miljofyrtarn-content"

const Miljofyrtarn: React.FC<{ location: ILocation; data: any }> = ({
    location,
    data,
}) => {
    const pagename = "Miljøfyrtårn"
    const { ingress, subheading, content } =
        data.miljofyrtarn.edges[0].node.frontmatter
    const ingressData = { pagename, ingress }

    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={ingressData}>
                <MiljofyrtarnContent
                    subheading={subheading}
                    content={content}
                />
            </Layout>
        </Fragment>
    )
}

export default Miljofyrtarn

export const data = graphql`
    query Miljofyrtarn {
        miljofyrtarn: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/CMSContent/Miljofyrtarn/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        pagename
                        heading
                        ingress
                        content
                    }
                }
            }
        }
    }
`
